import { PermissionType, queryVars, usePermissions } from "@/hooks/utils";
import {
  Activity,
  Additem,
  Bank,
  Book,
  Bookmark2,
  BuyCrypto,
  Category,
  ChartSquare,
  Convertshape2,
  EmptyWalletAdd,
  Folder,
  Grid6,
  InfoCircle,
  MenuBoard,
  Note,
  Paperclip,
  People,
  Receipt2,
  ReceiptItem,
  Save2,
  Setting,
  Shop,
  Tag2,
  Trade,
  TruckTick,
  UserCirlceAdd,
  WalletAdd,
  Receipt1,
} from "iconsax-react";
import { Item } from "./sidebarItem";
import { useQuery } from "react-query";
import { PendingListType } from "@/sample/queries";
import { authGetter } from "@/utils/utils";
import { useMemo } from "react";
import { useRouter } from "next/router";

const CLIENT_PERMISSIONS: Array<PermissionType> = [
  // CLIENT SEARCH
  "CAN_VIEW_CLIENT",

  // CLIENT FUNDING
  "CAN_VIEW_CLIENT_BANK_INFO",
  "CAN_VIEW_CLIENT_STORAGE_DEPOSITORY",

  // CLIENT PORTFOLIO
  "CAN_VIEW_CLIENT_PORTFOLIO",
  "CAN_VIEW_CLIENT_PORTFOLIO_HISTORY",

  // CLIENT WALLET
  "CAN_VIEW_CLIENT_WALLET",
  "CAN_VIEW_CLIENT_WALLET_HISTORY",
];

const MARKET_MANAGEMENT_PERMISSIONS: Array<PermissionType> = [
  // CROSS TRADE
  "CAN_CROSS_TRADE",
  "CAN_VIEW_CROSS_TRADE",

  // CASH ADVANCE
  "CAN_VIEW_CASH_ADVANCE",

  // DISPATCH
  "CAN_VIEW_DISPATCH_NAME",

  // INVOICE
  "CAN_VIEW_INVOICE",

  // OTC CONTRACTS
  "CAN_VIEW_OTC_TRADES",
  "CAN_VIEW_OTC_DISPATCH",

  // DERIVATIVE CONTRACTS
  "CAN_VIEW_OTC_FORWARDS_CONTRACTS",

  // WITHDRAWAL REQUEST
  "CAN_VIEW_WITHDRAWAL_REQUEST",

  // TRANSACTION BANKS
  "CAN_VIEW_TRANSACTION_BANKS",

  // SUSPENSE POSITIONS
  "CAN_VIEW_PORTFOLIO_SUSPENSE",

  // WAREHOUSE RECEIPT
  "CAN_VIEW_CIA_TO_CSD",

  // STOCK AND CASH COVER
  "CAN_VIEW_STOCK_AND_CASH_COVER_REPORT",
];

const REPORTS_PERMISSIONS: Array<PermissionType> = [
  // ORDER BOOK
  "CAN_VIEW_ORDER_BOOKS",

  // ERROR STATUS
  "CAN_VIEW_ERROR_LOG",

  // ACTIVITY STREAM
  "CAN_VIEW_ERROR_LOG",

  // CLIENT FUNDING
  "CAN_VIEW_CLIENT_FUND",

  // OPEN TRADES
  "CAN_VIEW_ORDER_BOOKS",

  // MATCHED TRADES
  "CAN_VIEW_MATCHED_ORDERS",
  "CAN_DOWNLOAD_CONTRACT_NOTE",

  // SECURITY POSITION
  "CAN_VIEW_CLIENT_SECURITY_POSITION",
];

const ACCOUNTING_PERMISSIONS: Array<PermissionType> = [
  // JOURNAL ENTRIES
  "CAN_VIEW_MANUAL_POSTINGS_HISTORY",

  // TRANSACTION MAPPING
  "CAN_VIEW_ACCOUNT_MAPPINGS",

  // ACCOUNTING REPORTS
  "CAN_VIEW_CHARTS_OF_ACCOUNTS",
];

const MARKET_ACCESS_PERMISSIONS: Array<PermissionType> = [
  // MANAGE USERS
  "CAN_VIEW_USER",
  "CAN_VIEW_GROUP",

  // OMS PROVIDER
  "CAN_VIEW_OMS_PROVIDER",

  // BOARDS
  "CAN_VIEW_BOARD",

  // SECURITIES
  "CAN_VIEW_SECURITY",
  "CAN_VIEW_LIQUIDATED_SECURITIES",
  "CAN_VIEW_PRODUCT_TYPE",
  "CAN_VIEW_LISTING",
];

export function useSidebarItems(active): Array<Item> {
  const { keys, query } = queryVars;
  const { checkPermission } = usePermissions();
  const { pathname } = useRouter();

  function isBasePath(base: string) {
    if (active === "") return pathname.startsWith(base);
    return false;
  }

  const { data: pendingListData, isLoading: pendingListLoading } = useQuery<
    PendingListType,
    "object"
  >(keys.pendingList, () => authGetter(query.pendingList));

  const {
    pending_cash_advance,
    pending_dispatch_request,
    pending_client_invoice,
    pending_withdrawal_request,
    pending_notification,
    pending_ewr,
    pending_otc_contract,
  } = useMemo(() => ({ ...pendingListData?.data }), [pendingListLoading]);

  return [
    {
      text: "Dashboard",
      icon: <Category />,
      active: true,
      link: "/dashboard",
      collapsed: false,
      visible: true,
    },
    {
      text: "Client",
      icon: <UserCirlceAdd />,
      active: false,
      link: "/client",
      collapsed: false,
      visible: CLIENT_PERMISSIONS.some(checkPermission),
    },
    {
      text: "Market Management",
      icon: <Shop />,
      active: false,
      collapsed:
        active === "Market Management" || isBasePath("/market-management"),
      link: "/market-management",
      visible: MARKET_MANAGEMENT_PERMISSIONS.some(checkPermission),
      children: [
        {
          text: "Cross Trade",
          icon: <Convertshape2 />,
          active: false,
          link: "/market-management/cross-trade",
          visible: checkPermission("CAN_CROSS_TRADE"),
          collapsed: false,
        },
        {
          text: "Cash Advance",
          icon: <Additem />,
          active: false,
          link: "/market-management/cash-advance",
          visible: checkPermission("CAN_VIEW_CASH_ADVANCE"),
          collapsed: false,
          pending: pending_cash_advance,
        },
        {
          text: "Dispatch",
          icon: <TruckTick />,
          active: false,
          link: "/market-management/dispatch",
          visible: checkPermission("CAN_VIEW_DISPATCH_NAME"),
          collapsed: false,
          pending: pending_dispatch_request,
        },
        {
          text: "Invoice",
          icon: <Book />,
          active: false,
          link: "/market-management/invoice",
          visible: checkPermission("CAN_VIEW_INVOICE"),
          collapsed: false,
          pending: pending_client_invoice,
        },
        {
          text: "OTC Contracts",
          icon: <Tag2 />,
          active: false,
          link: "/market-management/otc-contracts",
          visible:
            checkPermission("CAN_VIEW_OTC_TRADES") &&
            checkPermission("CAN_VIEW_OTC_DISPATCH"),
          collapsed: false,
          pending: pending_otc_contract,
        },
        {
          text: "Withdrawal Request",
          icon: <EmptyWalletAdd />,
          active: false,
          link: "/market-management/withdrawal-request",
          visible: checkPermission("CAN_VIEW_WITHDRAWAL_REQUEST"),
          collapsed: false,
          pending: pending_withdrawal_request,
        },
        {
          text: "Transaction Banks",
          icon: <Bank />,
          active: false,
          link: "/market-management/transaction-banks",
          visible: checkPermission("CAN_VIEW_TRANSACTION_BANKS"),
          collapsed: false,
        },
        {
          text: "Suspense Positions",
          icon: <Activity variant="Bulk" />,
          active: false,
          link: "/market-management/suspense-positions",
          visible: checkPermission("CAN_VIEW_PORTFOLIO_SUSPENSE"),
          collapsed: false,
        },
        {
          text: "Warehouse Receipt",
          icon: <Receipt1 />,
          active: false,
          link: "/market-management/warehouse-receipt",
          visible: checkPermission("CAN_VIEW_CIA_TO_CSD"),
          collapsed: false,
          pending: pending_ewr,
        },
      ],
    },
    {
      text: "Reports",
      icon: <Folder />,
      active: false,
      link: "/reports",
      collapsed: active === "Reports" || isBasePath("/reports"),
      visible: REPORTS_PERMISSIONS.some(checkPermission),
      children: [
        {
          text: "Order Books",
          icon: <Bookmark2 />,
          active: false,
          link: "/reports/order-books",
          visible: checkPermission("CAN_VIEW_ORDER_BOOKS"),
          collapsed: false,
        },
        {
          text: "Error Status",
          icon: <InfoCircle variant="Bulk" />,
          active: false,
          link: "/reports/error-status",
          visible: checkPermission("CAN_VIEW_ERROR_LOG"),
          collapsed: false,
        },
        {
          text: "Activity Stream",
          icon: <Note variant="Bulk" />,
          active: false,
          link: "/reports/activity-stream",
          visible: checkPermission("CAN_VIEW_ERROR_LOG"),
          collapsed: false,
        },
        {
          text: "Client Funding Log",
          icon: <WalletAdd />,
          active: false,
          link: "/reports/client-funding-log",
          visible: checkPermission("CAN_VIEW_CLIENT_FUND"),
          collapsed: false,
        },
        {
          text: "Open Trades",
          icon: <Paperclip />,
          active: false,
          link: "/reports/open-trades",
          visible: checkPermission("CAN_VIEW_ORDER_BOOKS"),
          collapsed: false,
        },
        {
          text: "Matched Trades",
          icon: <Trade variant="Bulk" />,
          active: false,
          link: "/reports/matched-trades",
          visible: checkPermission("CAN_VIEW_MATCHED_ORDERS"),
          collapsed: false,
        },
        {
          text: "Client Security Positions",
          icon: <BuyCrypto variant="Bulk" />,
          active: false,
          link: "/reports/client-security-positions",
          visible: checkPermission("CAN_VIEW_CLIENT_SECURITY_POSITION"),
          collapsed: false,
        },
      ],
    },
    {
      text: "Accounting",
      icon: <ReceiptItem />,
      active: false,
      link: "/accounting",
      collapsed: active === "Accounting" || isBasePath("/accounting"),
      visible: ACCOUNTING_PERMISSIONS.some(checkPermission),
      children: [
        {
          text: "Journal Entry",
          icon: <Save2 />,
          active: false,
          link: "/accounting/journal-entry",
          visible: checkPermission("CAN_VIEW_MANUAL_POSTINGS_HISTORY"),
          collapsed: false,
        },
        {
          text: "Accounting Reports",
          icon: <Book />,
          active: false,
          link: "/accounting/accounting-reports",
          visible: checkPermission("CAN_VIEW_CHARTS_OF_ACCOUNTS"),
          collapsed: false,
        },
        {
          text: "Transaction Mapping",
          icon: <Trade />,
          active: false,
          link: "/accounting/transaction-mapping",
          visible: checkPermission("CAN_VIEW_ACCOUNT_MAPPINGS"),
          collapsed: false,
        },
        {
          text: "Charts of Accounts",
          icon: <ChartSquare />,
          active: false,
          link: "/accounting/charts-of-accounts",
          visible: checkPermission("CAN_VIEW_CHARTS_OF_ACCOUNTS"),
          collapsed: false,
        },
      ],
    },
    {
      text: "Market Access",
      icon: <Setting variant="Bulk" />,
      active: false,
      link: "/market-access",
      collapsed: active === "Market Access" || isBasePath("/market-access"),
      visible: MARKET_ACCESS_PERMISSIONS.some(checkPermission),
      children: [
        {
          text: "Manage Users",
          icon: <People variant="Bulk" />,
          active: false,
          link: "/market-access/manage-users",
          visible: checkPermission("CAN_VIEW_USER"),
          collapsed: false,
        },
        {
          text: "OMS",
          icon: <Grid6 variant="Bulk" />,
          active: false,
          link: "/market-access/oms",
          visible: checkPermission("CAN_MANAGE_OMS_PROVIDER"),
          collapsed: false,
        },
        {
          text: "Boards",
          icon: <MenuBoard variant="Bulk" />,
          active: false,
          link: "/market-access/boards",
          visible: checkPermission("CAN_VIEW_BOARD"),
          collapsed: false,
        },
        {
          text: "Securities",
          icon: <Receipt2 variant="Bulk" />,
          active: false,
          link: "/market-access/securities",
          visible: checkPermission("CAN_VIEW_SECURITY"),
          collapsed: false,
        },
      ],
    },
  ];
}
