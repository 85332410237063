import { useRouter } from "next/router";
import { PermissionType, usePermissions } from "./usePermissions";
import { useIsAuthenticated } from "./useIsAuthenticated";

type FallbackPath = "/404" | "/" | (string & {});

export function useCanAccess<Permissions extends PermissionType>(
  ...permissionList: Permissions[]
) {
  const { replace, push } = useRouter();
  const { checkPermission } = usePermissions();

  const hasAtLeastOnePermission = permissionList.some(checkPermission);
  const hasAllRequiredPermissions = permissionList.every(checkPermission);
  const isAuthenticated = useIsAuthenticated();

  const permissions = permissionList.reduce((acc, permission) => {
    acc[permission] = checkPermission(permission);
    return acc;
  }, {} as Record<Permissions, boolean>);

  return {
    permissions,
    hasAllRequiredPermissions,
    hasAtLeastOnePermission,
    redirectTo: function <Path extends FallbackPath = "/404">(
      fallbackPath: Path = "/404" as Path
    ) {
      if (!hasAllRequiredPermissions) {
        if (isAuthenticated) replace(fallbackPath);
        else push("/");
      }
    },
  };
}
