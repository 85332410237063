import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";

import { authGetter } from "@/utils/utils";
import { PermissionListDataType, PermissionListType } from "@/sample/queries";
import { decrypt } from "@/utils/lib";

import { showErrorNotification } from "./showErrorNotification";
import { queryVars } from "./useIncrementalQuery";
import { useLogout } from "./useLogout";

type Modules =
  | ""
  | "ACCOUNT"
  | "ACCOUNTS"
  | "ACCOUNT_MAPPING"
  | "ACCOUNT_MAPPINGS"
  | "ACCOUNTING_POSTING"
  | "BANK_INFO"
  | "BOARD"
  | "CASH_COVER"
  | "CASH_ADVANCE"
  | "CHARTS_OF_ACCOUNTS"
  | "CIA_TO_CSD"
  | "CLIENT_FUND"
  | "CLOSING_PRICE_METHODOLOGY"
  | "CONTRACT_NOTE"
  | "CONTRACT_SETTLEMENT"
  | "CROSS_TRADE"
  | "CURRENCY"
  | "DERIVATIVE"
  | "DERIVATIVE_CONTRACT"
  | "DERIVATIVE_CONTRACT_SETTLEMENT"
  | "DISPATCH"
  | "DISPATCH_NAME"
  | "DOCUMENT_TYPE"
  | "ERROR_LOG"
  | "EVENT"
  | "FIXED_INCOME_SETTINGS"
  | "LISTING"
  | "LIQUIDATED_SECURITIES"
  | "LOCATION_DIFFERENTIALS"
  | "INITIAL_DISPATCH"
  | "INVOICE"
  | "MANUAL_POSTINGS_HISTORY"
  | "MATCHED_ORDERS"
  | "MARGIN_ACCOUNT"
  | "RULES"
  | "ORDER_BOOKS"
  | "OTC_FORWARDS_CONTRACTS"
  | "OTC_TRADES"
  | "OTC_DISPATCH"
  | "PARTY_CATEGORY"
  | "PERMISSION"
  | "PORTFOLIO"
  | "PORTFOLIO_HISTORY"
  | "PORTFOLIO_SUSPENSE"
  | "PRODUCT_TYPE"
  | "SECURITIES"
  | "SECURITY"
  | "SECURITY_POSITION"
  | "SECURITY_PRICE"
  | "SETTINGS"
  | "STORAGE_DEPOSITORY"
  | "STOCK_AND_CASH_COVER"
  | "STOCK_AND_CASH_COVER_REPORT"
  | "STOCK_COVER"
  | "SUSPENSE_POSITION"
  | "TRANSACTION_BANKS"
  | "TRANSACTION_FEE"
  | "WALLET"
  | "WALLET_HISTORY"
  | "WALLET_STATEMENT"
  | "WAREHOUSE"
  | "WITHDRAWAL_REQUEST";

type Person =
  | ""
  | "CLIENT"
  | "GROUP"
  | "ITEM"
  | "LOGISTICS_OFFICER"
  | "OMS_PROVIDER"
  | "TRADED_CLIENT"
  | "USER";

type Action =
  | "ADD"
  | "AUDIT"
  | "APPROVE"
  | "ACTIVATE"
  | "CAN"
  | "CREATE"
  | "CREATE_OR_UPDATE"
  | "CONVERT"
  | "CROSS_TRADE"
  | "DELETE"
  | "DOWNLOAD"
  | "EDIT"
  | "FUND"
  | "GIVE"
  | "LIEN"
  | "LOCK"
  | "LOG"
  | "MARK"
  | "MAKE"
  | "MANAGE"
  | "RESEND"
  | "REVERT"
  | "SET"
  | "SUSPEND"
  | "TRANSFER"
  | "UPDATE"
  | "VALIDATE"
  | "VIEW";

type Space<D> = D extends "" ? "" : D extends string ? `_${D}` : never;
type LegacyPermissions = "LOGISTICS_OFFICER";
type ModulePermissions = `CAN${Space<Action>}${Space<Person>}${Space<Modules>}`;

export type PermissionType = ModulePermissions | LegacyPermissions;

export function usePermissions(value: keyof PermissionListDataType = "id") {
  const logout = useLogout();
  const { keys, query } = queryVars;
  const {
    data: permissionsData,
    isLoading: permissionLoading,
    isError: permissionsError,
  } = useQuery<PermissionListType>(keys.permissions, () => {
    return authGetter(query.permissions);
  });

  const permissionOptions = useMemo(() => {
    return (
      permissionsData?.data?.map((option) => ({
        label: option.name,
        value: `${option[value]}`,
      })) ?? []
    );
  }, [permissionLoading]);

  if (permissionsError) showErrorNotification("loading permissions");

  const checkPermission = useCallback<(permission: PermissionType) => boolean>(
    (permission: PermissionType) => {
      try {
        const data = localStorage.getItem("ecn.user.permissions");
        const permissions = JSON.parse(decrypt(data ?? "") ?? "[]");
        return permissions.includes(permission.toLocaleLowerCase());
      } catch (error) {
        // TODO: remove this when the issue is fixed
        if (process.env.NODE_ENV === "development") console.error(error);
        logout();
        return false;
      }
    },
    [permissionLoading]
  );
  return {
    checkPermission,
    permissionOptions,
  };
}
