import {
  MarketMonitoringDataType,
  PriceMonitoringType,
  SecurityMonitoringDataType,
  PriceMonitoringDataType,
} from "@/sample/queries";
import { useWebSocket } from "@/hooks/utils";
import { useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";
import {
  TopCharts,
  Skeletal,
  OrderBook,
  OrderBookMenu,
  TimeLine,
  ChartType,
  MarketDrawer,
} from ".";
import { TradingChart } from "./financialChart";
import { MinutesStockChart } from "./stockChart";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

import { useElementSize } from "@mantine/hooks";
import _ from "lodash";
import Big from "big.js";

interface MainContentProps {
  marketInfo?: MarketMonitoringDataType;
  marketInfoLoading: boolean;
  securitiesLoading: boolean;
  securityInfo?: SecurityMonitoringDataType;
}

export function MainContent({
  marketInfo,
  marketInfoLoading,
  securitiesLoading,
  securityInfo,
}: MainContentProps) {
  const { query } = useRouter();
  const { top_clients_by_count, top_clients_by_value } = marketInfo ?? {};
  const { view, orders, security, time } = query as Record<string, string>;

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  // PRICE INFORMATION
  const { data: priceInfo, isLoading: priceInfoLoading } = useWebSocket<
    PriceMonitoringType,
    PriceMonitoringDataType
  >({
    key: ["prices", security],
    pathname: `securities/${security}/prices`,
    queryFn: ({ messages }) => messages,
    options: {
      enabled: !!security,
    },
  });

  const { ref: chartRef, width, height } = useElementSize();
  const timing = {
    "1H": 1,
    "4H": 4,
    "1D": 24,
    "1W": 7 * 24,
    "1M": 30 * 24,
  };

  const tradingData = useMemo(() => {
    const prices: {
      matched_qty: number;
      price: number;
      date: Date;
    }[] = [];

    const getGroups = (prices) => {
      let lastTime = dayjs().startOf("year").year(0).toDate();
      return _.groupBy(prices, ({ date }: { date: Date }) => {
        const diff =
          dayjs(date).diff(dayjs(lastTime)) / 1000 / 3600 <= timing[time];
        return diff ? lastTime : (lastTime = dayjs(date).minute(0).toDate());
      }) as Record<
        string,
        {
          matched_qty: number;
          price: number;
          date: number;
        }[]
      >;
    };

    for (let { price, date, matched_qty } of priceInfo?.prices ?? []) {
      prices.push({
        price: +price,
        date: dayjs(
          date.substring(0, 23),
          "YYYY-MM-DD[T]HH:mm:ss.SSS"
        ).toDate(),
        matched_qty,
      });
    }

    prices?.sort((a, b) => dayjs(a.date).diff(b.date));
    const reducer = (acc, next) => acc.plus(next);

    const last = { closing_price: 0 };
    const OHLC = Object.entries(getGroups(prices)).map(([timestamp, range]) => {
      const closing =
        range.reduce(
          (acc, { matched_qty, price }) => acc + matched_qty * price,
          0
        ) / _.sumBy(range, "matched_qty");

      const group = {
        date: dayjs(timestamp).toDate(),
        open: Big(last.closing_price).round(2).valueOf(),
        volume: parseFloat(_.map(range, "matched_qty").reduce(reducer, Big(0))),
        high: Big(_.maxBy(range, "price")?.price ?? 0)
          .round(2)
          .valueOf(),
        close: Big(closing).round(2).valueOf(),
        low: Big(_.minBy(range, "price")?.price ?? 0)
          .round(2)
          .valueOf(),
      };

      last.closing_price = closing;
      return group;
    });

    return OHLC;
  }, [priceInfoLoading, time]);

  return (
    <main className="grid grid-rows-[auto_1fr] grid-cols-[1fr_minmax(300px,_max-content)] gap-2 overflow-auto">
      <div className="flex items-center justify-between gap-10 px-4 py-3 overflow-auto bg-ecn-light-red whitespace-nowrap">
        <TimeLine />
        <ChartType />
      </div>
      <OrderBookMenu />
      <div className="items-stretch gap-2 overflow-auto contents">
        <section className="grid grid-rows-[1fr,_auto] h-full gap-2 overflow-auto">
          <Skeletal visible={securitiesLoading}>
            <div
              ref={chartRef}
              className="relative grid h-full bg-ecn-light-red"
              style={{
                height: (width * 9) / 16,
              }}
            >
              {view === "Trading" ? (
                <TradingChart
                  data={tradingData}
                  height={height}
                  width={width}
                />
              ) : (
                // <DepthChart width={width} height={height} data={depthData} />
                <></>
              )}
            </div>
          </Skeletal>
          <TopCharts
            topTrades={top_clients_by_value}
            topClients={top_clients_by_count}
            isLoading={securitiesLoading || marketInfoLoading}
          />
        </section>
        <Skeletal visible={marketInfoLoading}>
          <aside className="grid auto-rows-[1fr_auto] h-full gap-2 overflow-auto">
            <OrderBook
              setDrawerOpen={setDrawerOpen}
              securityInfo={securityInfo}
              marketInfo={marketInfo}
              orders={orders}
            />
          </aside>
        </Skeletal>
      </div>
      <MarketDrawer
        isDrawerOpen={isDrawerOpen}
        setDrawerOpen={setDrawerOpen}
        matchedOrders={marketInfo?.matched_orders}
        matchedOrdersLoading={marketInfoLoading}
      />
    </main>
  );
}
