import { Button, Group } from "@mantine/core";

interface ApproveRejectProps {
  handleReject(): void;
  handleApprove(): void;
  disableReject: boolean;
  disableApprove: boolean;
  approvalLoading: boolean;
  rejectionLoading: boolean;
  canApprove?: boolean;
  canReject?: boolean;
}

export function ApproveReject({
  disableApprove,
  disableReject,
  handleApprove,
  handleReject,
  approvalLoading = false,
  rejectionLoading = false,
  canApprove = true,
  canReject = true,
}: ApproveRejectProps) {
  return (
    <Group>
      <Button
        hidden={!canApprove}
        className="border-[#86C105] text-[#86C105] hover:bg-[#86C105] hover:text-white"
        disabled={approvalLoading || disableApprove}
        onClick={handleApprove}
        loading={approvalLoading}
      >
        Approve
      </Button>
      <Button
        hidden={!canReject}
        className="border-[#E1261C] text-[#E1261C] hover:bg-[#E1261C] hover:text-white"
        disabled={rejectionLoading || disableReject}
        onClick={handleReject}
        loading={rejectionLoading}
      >
        Reject
      </Button>
    </Group>
  );
}
