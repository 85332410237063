import { deleteCookie, getCookies } from "cookies-next";
import { useRouter } from "next/router";
import { useCallback } from "react";

const removeCookie = (cookie: string) => deleteCookie(cookie);

export function useLogout(selfDestruct = true) {
  const { push, isReady } = useRouter();

  const logout = useCallback(() => {
    if (selfDestruct) {
      removeCookie("ecn.client.auth.token");
      localStorage.removeItem("ecn.user.permissions");
      sessionStorage.clear();
    } else {
      localStorage.clear();
      const cookies = getCookies();
      Object.keys(cookies).forEach(removeCookie);
    }

    push("/");
  }, [isReady]);

  return logout;
}
